import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
 
@Injectable({
  providedIn: 'root'
})
export class InstituicaoService {
  urlAPI = 'https://backend.anpet.org.br/public/api/'; // api rest fake
  constructor(
    private httpClient: HttpClient,
    ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  getinstituicao(){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'instituicao/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  novaInstituicao(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'instituicao/cadastrar',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  
}
