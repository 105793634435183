import { Component, OnInit } from '@angular/core';
import { DashBoardComponent } from '../../pages/pages/dash-board/dash-board.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SocioService } from 'src/app/services/socio.service';


declare var $;

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  user: any;
  imagemperfil = 'https://backend.anpet.org.br/storage/app/perfil/Silhueta-png-cinzento-300x284.png'

  constructor(
    private router: Router,
    private userservice: UserService,
    private socioService: SocioService,

  ) {
  }
  menu = [
  ];
  teste(){
    console.log('teste')
  }
  ngOnInit() { 
    const id = sessionStorage.getItem('usuario')
    if(!id){
      this.router.navigate(['/']);
    }
    this.userservice.getusuariosunico(id).then((res)=>{
      this.user = res.body;
      if(this.user.foto){
        this.imagemperfil = 'https://backend.anpet.org.br/storage/app/'+this.user.foto;
      }

// console.log(res.body)
if (res.body.grupo_id != 2 && res.body.grupo_id != 7) {
  this.socioService.verificarSocio(id).then((r)=>{
      if (r.body) {
        this.menu = [
          { titulo: 'Início', link: '/Main/Home', icone: 'assets/dist/img/Icon/Ico_V_00.png' },
          { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/Ico_V_01.png' },
          { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/Ico_V_02.png' },
          { titulo: 'Lista de associados', link: '/Main/Lista-socio', icone: 'assets/dist/img/Icon/Ico_V_01.png' },
          { titulo: 'Acompanhar pagamento', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/Ico_V_06.png' },
          // { titulo: 'Inscrição', link: '/Main/Inscricao', icone: 'assets/dist/img/Icon/Ico_V_03.png' },
          // { titulo: 'Empenho', link: '/Main/empenho', icone: 'assets/dist/img/Icon/Ico_V_05.png' },
          { titulo: 'Lista de Empenhos', link: '/Main/lista-empenhos', icone: 'assets/dist/img/Inscricoes.svg' },
          // { titulo: 'Convite para o jantar', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_07.png' },
          { titulo: 'Recibo', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_09.png' },
          // { titulo: 'Certificados', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_10.png' },
          { titulo: 'Anais', link: '/Main/Lista-anais', icone: 'assets/dist/img/Icon/Ico_V_11.png' },
      
        ]; 
      } else {
        this.menu = [
          { titulo: 'Início', link: '/Main/Home', icone: 'assets/dist/img/Icon/Ico_V_00.png' },
          { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/Ico_V_01.png' },
          { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/Ico_V_02.png' },
          { titulo: 'Acompanhar pagamento', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/Ico_V_06.png' },
          // { titulo: 'Inscrição', link: '/Main/Inscricao', icone: 'assets/dist/img/Icon/Ico_V_03.png' },
          // { titulo: 'Empenho', link: '/Main/empenho', icone: 'assets/dist/img/Icon/Ico_V_05.png' },
          { titulo: 'Lista de Empenhos', link: '/Main/lista-empenhos', icone: 'assets/dist/img/Inscricoes.svg' },
          { titulo: 'Convite para o jantar', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_07.png' },
          { titulo: 'Recibo', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_09.png' },
          // { titulo: 'Certificados', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_10.png' },
          { titulo: 'Anais', link: '/Main/Lista-anais', icone: 'assets/dist/img/Icon/Ico_V_11.png' },
      
        ];  
      }
  })

} else {
  this.menu = [
    { titulo: 'Início', link: '/Main/Home', icone: 'assets/dist/img/Icon/Ico_V_00.png' },
    { titulo: 'Perfil', link: '/Main/Perfil', icone: 'assets/dist/img/Icon/Ico_V_01.png' },
    { titulo: 'Associação', link: '/Main/Associacao', icone: 'assets/dist/img/Icon/Ico_V_02.png' },
    { titulo: 'Acompanhar pagamento', link: '/Main/Acompanhar', icone: 'assets/dist/img/Icon/Ico_V_06.png' },
    { titulo: 'Lista de associados', link: '/Main/Lista-socio', icone: 'assets/dist/img/Icon/Ico_V_01.png' },
    { titulo: 'Lista de usuários', link: '/Main/Listar-usuario', icone: 'assets/dist/img/Icon/Ico_V_04.png' },
    // { titulo: 'Inscrição', link: '/Main/Inscricao', icone: 'assets/dist/img/Icon/Ico_V_03.png' },
    // { titulo: 'Lista de Inscritos', link: '/Main/Lista-inscrito', icone: 'assets/dist/img/Icon/Ico_V_11.png' },
    // { titulo: 'Empenho', link: '/Main/empenho', icone: 'assets/dist/img/Icon/Ico_V_05.png' },
    { titulo: 'Lista de Empenhos', link: '/Main/lista-empenhos', icone: 'assets/dist/img/Inscricoes.svg' },
    // { titulo: 'Convite para o jantar', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_07.png' },
    { titulo: 'Balanço', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_08.png' },
    { titulo: 'Recibo', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_09.png' },
    // { titulo: 'Certificados', link: '/Main/Em-breve', icone: 'assets/dist/img/Icon/Ico_V_10.png' },
    { titulo: 'Anais', link: '/Main/Lista-anais', icone: 'assets/dist/img/Icon/Ico_V_11.png' },
    { titulo: 'Portal da Transparência', link: '/Main/transparencia', icone: 'assets/dist/img/Icon/Ico_V_08.png' },
    // { titulo: 'Inscrição Teste', link: '/Main/inscricao-teste', icone: 'assets/dist/img/Icon/Ico_V_11.png' },

 

  ];
  
}
   
     }).catch((err)=>{ 
       console.log(err)
     })

    $(document).ready(() => {
      $('.sidebar-menu').tree();
    });
  }
  navigate(rota) {
    this.router.navigate([rota]);
    setTimeout(function(){

    var t =  $('.content-wrapper').height();

      var doc =  $(document).height();
      $(document).height();
      var intViewportHeight = t + 300 + 'px';      
    $('body').css('min-height',intViewportHeight);

    },1000)
  }
  logout(){
    sessionStorage.setItem('usuario','')
    this.router.navigate(['/'])
  }

}
