import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  ngOnInit(): void {
    // var intViewportHeight = window.innerHeight + 300;
    // var element = document.getElementsByTagName("body")[0];
    // setTimeout(function(){
    //   element.setAttribute('style','height:'+intViewportHeight+'px')

    // },500)



    // console.log(element)
  }
  title = 'ANPET';
} 
