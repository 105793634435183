import { Component, OnInit, Inject } from '@angular/core';
import { ModalBasicoComponent } from '../modal-basico/modal-basico.component';
import { User } from 'src/app/models/user';
import { SocioService } from "src/app/services/socio.service";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { InstituicaoService } from 'src/app/services/instituicao.service';
import { TipoInscricaoService } from 'src/app/services/tipo-inscricao.service';
import { InscricaoService } from "src/app/services/inscricao.service";
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { EmpenhoService } from "src/app/services/empenho.service";
import { convertPropertyBinding } from '@angular/compiler/src/compiler_util/expression_converter';
@Component({
  selector: 'app-modal-empenho-unico',
  templateUrl: './modal-empenho-unico.component.html',
  styleUrls: ['./modal-empenho-unico.component.scss']
})
export class ModalEmpenhoUnicoComponent implements OnInit {
  user: User;
  foto: File;
  headers = ["nome", "tipo", "Valor"];
  rows = []
  imagemperfil = 'https://backend.anpet.org.br/storage/app/perfil/Silhueta-png-cinzento-300x284.png'
  idExterno = this.data.id;
  empenho = {
    id: ' ',
    data: ' ',
    valor: 0,
    desconto: 0,
    valorFinal: 0,
    nome: ' ',
    email: ' ',
    telefone: ' ',
    observacao: '',
    status: '',
    nota: null,
  }

  candidato = {
    id: null,
    nome: 'nome do candidato',
    cpf: '3333333333',
    status: 'Em analise'
  };
  candidatoList = [];

  form = {
    id: '',
    email: ' ',
    cpf: ' ',
    comprovante: null,
    senha: ' ',
    autorizadoTipo: 1,
    telefone: ' ',
    tipoUsuario: ' ',
    universidade: {
      id: ''
    },
    nome: ' ',
    password: ''
  }
  showNotas: boolean = false;
  showRecibo: boolean = false;

  visible: boolean = true;
  perfilForm: FormGroup;
  nomeresp = new FormControl('', [Validators.required]);
  emailresp = new FormControl('', [Validators.required]);
  instituicao: any;
  cpf = new FormControl('', [Validators.required]);
  instituicaoCon = new FormControl('', [Validators.required]);
  instituicaoCheia: any;
  tel = new FormControl('',);
  senha = new FormControl('', [Validators.required]);
  valoresassocia: any;
  documento: File;
  recibo: File;
  valoresinscricao: any;
  tipoInscricao: any;
  filtroCpf: any;

  constructor(
    public dialogRef: MatDialogRef<ModalBasicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userservice: UserService,
    private socioService: SocioService,
    private formBuilder: FormBuilder,
    private router: Router,
    private instituicaoservi: InstituicaoService,
    private tipoInscricaoService: TipoInscricaoService,
    private inscricaoService: InscricaoService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    public empenhoService: EmpenhoService
  ) { }
  onAdd = new EventEmitter();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  senhaFormControl = new FormControl('', [
    Validators.required,
  ]);

  async ngOnInit() {
    this.iniciarValores();
  }
  // async pegarusuario(id) {
  //   this.userservice.getusuariosunico(id).then((res) => {
  //     console.log(res.body)
  //     if (res.body.autorizadoTipo != 2) {
  //       let mnsg = 'Para concluir sua inscrição, aguarde autorização do administrador.'
  //       let modal = this.dialog.open(ModalBasicoComponent, {
  //         width: '33vw',
  //         data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
  //       });
  //       modal.componentInstance.onAdd.subscribe(() => {
  //         this.router.navigate(['/Main/Home']);
  //       });
  //       modal.afterClosed().subscribe(result => {
  //         this.router.navigate(['/Main/Home']);
  //       });
  //       this.router['./Main/Home']
  //       return true;
  //     }
  //     if (res.body.grupo_id == 2) {
  //       this.iniciarValores()
  //     }

  //     this.user = res.body
  //     this.visible = false;

  //   }).catch((err) => {
  //   })
  // }
  async iniciarValores() {
    this.empenhoService.getEmpenhoUnico(this.data.id).then((res) => {
      this.valorInscricao()
      res = res.body[0];

      this.candidatoList = res.tax_user;

      this.candidatoList.map((r) => {
        if (r.usuario.tipoUsuario == undefined) {
          r.usuario.tipoUsuario = 1
          r.usuario.autoriza = 'pendente'
          return true
        }
        if (r.usuario.autorizadoTipo == 1) {
          r.usuario.autoriza = 'pendente'
        } else if (r.usuario.autorizadoTipo == 2) {
          r.usuario.autoriza = 'autorizado'
        } else if (r.usuario.autorizadoTipo == 3) {
          r.usuario.autoriza = 'recusado'
        }



      })

      if (!res.desconto) {
        res.desconto = '0';
      }
      res.created_at = res.created_at.substring(0, 10);
  
      this.empenho = {
        id: res.id,
        data: res.created_at,
        valor: 0,
        desconto: res.desconto,
        valorFinal: 0,
        nome: res.responsavel,
        email: res.email,
        telefone: res.telefone,
        observacao: res.observacao,
        status: res.status,
        nota: res.nota
      }

      this.valorInscricao();
      this.pegarCertificados();

    })
  }
  onNoClick(): void {
    this.onAdd.emit();
    this.dialogRef.close();
  }
  cancelar() {
    this.dialogRef.close();
  }
  getinstituicao() {
    this.instituicaoservi.getinstituicao().then((ins) => {

      this.instituicao = Array.from(new Set(ins.body.map(s => s.nome))).map(nome => {
        return {
          id: ins.body.find(s => s.nome === nome).id,
          nome: nome,
          sigla: ins.body.find(s => s.nome === nome).sigla
        }
      })
      this.instituicaoCheia = this.instituicao;
      this.instituicao
    }).catch((err) => {
      console.log(err)
    })
  }
  async pegarCertificados(){
    this.inscricaoService.getInscritos(3).then((res) => {
      console.log(res);
      
      this.candidatoList.map((r) => {
        if (r.usuario.autorizadoTipo == 2) {
          // codigo de certificado
          r.certificadoIs = false;
          res.body.map((inscrito)=>{
            if (inscrito.usuario.id == r.id_usuario) {
              r.certificado = inscrito.certificado;
              r.certificadoIs = true;
            }
          });
          console.log();
        }
      })
    })
  }
  async valorInscricao() {
    this.tipoInscricaoService.ValorInscricao().then((res) => {
      this.valoresinscricao = res.body;
      this.candidatoList.map((can) => {
        can.usuario.valor = this.valoresinscricao.filter((r) => {
          if (r.id == can.usuario.tipoUsuario) {
            return r;
          }
        })
        can.usuario.valor = can.usuario.valor[0].valor
      })
      this.calcValorTotal();
    }).catch((err) => {
    })

    this.tipoInscricaoService.GetTipodeInscricao().then((res) => {
      this.tipoInscricao = res.body
      this.candidatoList.map((can) => {
        can.usuario.tipo = this.tipoInscricao.filter((r) => {
          if (r.id == can.usuario.tipoUsuario) {
            return r.tipo[0]
          }
        })
        can.usuario.tipo = can.usuario.tipo[0].tipo
      })
    })

    this.visible = false;
    return true
  }

  salvar() {
    this.visible = true;

    let form: any;
    form = {
      id: this.form.id,
      email: this.form.email,
      cpf: this.form.cpf,
      telefone: this.form.telefone,
      atividade: this.form.tipoUsuario,
      universidade: this.instituicaoCon.value,
      nome: this.form.nome,
      senha: this.senha.value
    }
    if (this.foto) {
      let imagem = this.foto;
      let fd = new FormData();
      fd.append('image', imagem, imagem.name);
      this.userservice.subirfotoPerfil(form.id, fd).then((res) => {
        this.foto = null
      });
    }
    if (form.atividade == 1) {
      this.visible = true;
      form.autorizadoatividade = 2
      this.userservice.alterarusuario(form.id, form).then((res) => this.ngOnInit()).catch((e) => console.log(e))
    } else if (this.documento != null && form.atividade != 1 || this.documento == null && this.form.comprovante != null && form.atividade != 1) {

      if (this.documento) {
        this.visible = true;
        form.autorizadoatividade = 1
        let imagem = this.documento;
        let fd = new FormData();
        fd.append('image', imagem, imagem.name);
        this.userservice.subirDocument(form.id, fd).then((res) => { form.comprovante = res.comprovante; this.userservice.alterarusuario(form.id, form).then((res) => this.ngOnInit()).catch((e) => console.log(e)) });
      } else {
        // form.autorizadoatividade = 1
        this.userservice.alterarusuario(form.id, form).then((res) => this.ngOnInit()).catch((e) => console.log(e))
      }
    } else {
      this.visible = true;
      form.autorizadoatividade = 1
      this.userservice.alterarusuario(form.id, form).then((res) => this.ngOnInit()).catch((e) => console.log(e))
    }
  }

  salvarEmpenho() {
    this.visible = true;
    let form: any;
    form = {
      id: this.data.id,
      responsavel: this.empenho.nome,
      email: this.empenho.email,
      telefone: this.empenho.telefone,
      observacao: this.empenho.observacao,
      desconto: this.empenho.desconto,
    }

    this.empenhoService.alterarEmpenho(form).then((res) => {
      // console.log(res);
      this.iniciarValores();
    })
      .catch((e) => console.log(e))

  }

  excluirEmpenho() {
    this.empenhoService.deletarEmpenho(this.empenho.id).then((res) => {
      this.dialogRef.close();
    });

  }
  alterarStatus(status) {
    this.visible = true;
    let form: any;
    form = {
      id: this.data.id,
      status: status
    }

    this.empenhoService.alterarEmpenho(form).then((res) => {
      // console.log(res);
      this.iniciarValores();
      if (status = 2) {
        this.verificarNota();
      }
    })
      .catch((e) => console.log(e))
  }
  verificarNota() {
    if (this.empenho.nota) {
      this.increverUsuariosAprovados();
    } else {
      let mnsg = '<h3> Por favor adicionar nota antes de aprovar. </h3>';
      let modal = this.dialog.open(ModalBasicoComponent, {
        width: '33vw',
        data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
      });
    }

  }
  desconto() {
    this.visible = true;
    let form: any;
    if (this.empenho.desconto === 0) {
      console.log("desconto nulo");

    }
    form = {
      id: this.data.id,
      desconto: this.empenho.desconto,
    }

    this.empenhoService.alterarEmpenho(form).then((res) => {
      // console.log(res);
      this.iniciarValores();
    })
      .catch((e) => console.log(e))
  }
  enviarNota(event) {
    this.documento = <File>event.target.files[0];
  }
  arquivo(event) {
    this.foto = <File>event.target.files[0];
    let tes: any;
    var reader = new FileReader()
    reader.readAsDataURL(this.foto);
    reader.onload = () => {
      tes = reader.result as string;
      this.imagemperfil = tes;
    };
  }
  comprovante(event) {
    this.documento = <File>event.target.files[0];
  }
  showNotasControle() {
    // this.showNotas = true;
    if (this.showNotas) {
      this.showNotas = false;
    } else {
      this.showNotas = true;
    }
  }
  salvarComprovante() {
    // conferir isso
    let imagem = this.documento;
    let fd = new FormData();
    fd.append('image', imagem, imagem.name);
    this.visible = false;
    this.empenhoService.alterarNota(this.data.id, fd).then((res) => {
    this.visible = true;

      this.ngOnInit();
    })
  }
  verNota(){
    window.open('https://backend.anpet.org.br/storage/app/'+this.empenho.nota,'blank')
  }
  showReciboControle() {
    // this.showNotas = true;
    if (this.showRecibo) {
      this.showRecibo = false;
    } else {
      this.showRecibo = true;
    }
  }
  enviarRecibo(event) {
    this.recibo = <File>event.target.files[0];
  }
  salvarRecibo() {
    // conferir isso
    let imagem = this.recibo;
    let fd = new FormData();
    fd.append('image', imagem, imagem.name);

    this.empenhoService.alterarRecibo(this.form.id, fd).then((res) => {
      // console.log(res);
      this.iniciarValores();
    })
  }
  calcValorTotal() {
    let valorTotal: any;
    this.candidatoList.map((r) => {
      if (valorTotal) {
        valorTotal = valorTotal + parseInt(this.valoresinscricao[r.usuario.tipoUsuario - 1].valor);
      } else {
        valorTotal = parseInt(this.valoresinscricao[r.usuario.tipoUsuario - 1].valor);
      }
    })
    this.empenho.valor = valorTotal;
    this.empenho.valorFinal = valorTotal - this.empenho.desconto;

  }

  adicionar() {
    let jaESocio = false;
    let candidatostrip = {
      nome: this.candidato.nome,
      cpf: this.candidato.cpf,
      id: this.candidato.id
    }
    this.socioService.verificarSocio(this.candidato.id).then((res) => {
      if (res.body.id != undefined) {
        jaESocio = true;
      }
    })

    for (var i = 0; i < this.candidatoList.length; i++) {
      if (this.candidatoList[i].id === this.candidato.id) {
        return;
      }
    }
    if (!jaESocio) {
      // this.candidatoList.push(candidatostrip);
      // console.log(this.candidatoList);


    } else {
      console.log("é socio");
      let modal = this.dialog.open(ModalBasicoComponent, {
        width: '33vw',
        data: { mnsg: "<h3>Usuario é associado e não pode participar de empenho</h3>", buttonOK: true, buttonCancelar: false }
      });

    }


  }
  adicionarNovoUser() {
    let listaNovos = [this.candidato];
    let tax = {
      id_empenho: this.empenho.id,
      usuarios_id: listaNovos
    }

    this.socioService.verificarSocio(this.candidato.id).then((res) => {
      if (res.body.id != undefined) {
        let mnsg = "<h3>Como este usuário e um associado ANPET esse ano a inscrição é gratuita</h3>"
        let modal = this.dialog.open(ModalBasicoComponent, {
          width: '33vw',
          data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
        });
        modal.componentInstance.onAdd.subscribe(() => {


        });

        modal.afterClosed().subscribe(result => {
          this.candidato = {
            id: null,
            nome: null,
            cpf: null,
            status: null
          };
          this.ngOnInit();
        });
        return true
      } else {
        this.inscricaoService.getInscritosnoEvento(3,this.candidato.id).then((res) => {
          if (res.body.length) {
            let mnsg = "<h3>Este usuário já está inscrito no evento. </h3>"
            let modal = this.dialog.open(ModalBasicoComponent, {
              width: '33vw',
              data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
            });
            modal.componentInstance.onAdd.subscribe(() => {
    
    
            });
    
            modal.afterClosed().subscribe(result => {
              this.candidato = {
                id: null,
                nome: null,
                cpf: null,
                status: null
              };
              this.ngOnInit();
            });
            return true
          } else {

            this.empenhoService.cadastroUsuarioEmpenho(tax).then((r) => {
              this.visible = false;

              let mnsg = '';
              if (r.body.type == 1) {
                mnsg = '<h3>Todos os usuarios foram salvos no seu empenho.</h3>'

              } else if (r.body.type == 2) {
                mnsg = '<h3>hoveram usuarios que não puderam fazer parte do seu empenho. pois já tem empenho para eles no envento <br />são eles<br /> </h3>';
                let lista = ''
                let item = ''


                for (let i = 0; i < r.body.usuarios.length; i++) {
                  let nome = r.body.usuarios[i];
                  item = item + '<li>' + nome + '</li>'

                }
                lista = '<ul>' + item + '</ul>'

                mnsg = mnsg + lista


              }
              let modal = this.dialog.open(ModalBasicoComponent, {
                width: '33vw',
                data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
              });
              modal.componentInstance.onAdd.subscribe(() => {


              });

              modal.afterClosed().subscribe(result => {
                this.candidato = {
                  id: null,
                  nome: null,
                  cpf: null,
                  status: null
                };
                this.ngOnInit();
              });
              // this.router['./Main/Home']

            }).catch((err) => {
              console.log(err)
            })
          }
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  increverUsuariosAprovados() {
    let obj: any;
    let candidatosAprovados: any = [];
    let candidatosReprovados: any = [];

    this.candidatoList.map((res) => {
      if (res.usuario.autorizadoTipo == 2) {
        candidatosAprovados.push({
          usuario_id: res.usuario.id,
          valor: this.valoresinscricao[res.usuario.tipoUsuario - 1].valor,
          eventos_id: 3,
          migrado: 2
        });
      } else {
        candidatosReprovados.push(res.usuario.nome)
      }
    })
    // console.log('lista candidatos');
    // console.log(this.candidatoList);
    // console.log('lista candidatos aprovados');
    // console.log(candidatosAprovados);
    
    obj = {
      empenho_id: this.empenho.id,
      inscrever: candidatosAprovados
    }
    // console.log('objeto final');
    // console.log(obj);
    if (candidatosAprovados.length) {

      if (candidatosReprovados.length) {

        
        this.inscricaoService.inscreverUsuariosEmpenho(obj).then((res) => {
          let mnsg = '<h3>Hoveram usuarios que não puderam serem inscritos no evento pois estão ou pendentes ou reprovados.<br />São eles:<br /> </h3>';
          let lista = ''
          let item = ''

          for (let i = 0; i < candidatosReprovados.length; i++) {
            let nome = candidatosReprovados[i];
            item = item + '<li>' + nome + '</li>'

          }
          lista = '<ul>' + item + '</ul>'

          mnsg = mnsg + lista
          let modal = this.dialog.open(ModalBasicoComponent, {
            width: '33vw',
            data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
          });
          this.ngOnInit();
        })
        

      }else {
        // caso tudo ok envia todos os aprovados
        this.inscricaoService.inscreverUsuariosEmpenho(obj).then((res) => {
              let mnsg = '<h3>Todos usuarios aprovados foram inscritos </h3>';
              let modal = this.dialog.open(ModalBasicoComponent, {
                width: '33vw',
                data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
              });
              this.ngOnInit();
            })
      }

    }else{ 
      let mnsg = '<h3> nenhum Usuario pode ser inscrito, todos estão pendentes ou reprovados</h3>';
      let modal = this.dialog.open(ModalBasicoComponent, {
        width: '33vw',
        data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
      });
    }
    
  }

  removerUser(id) {
    this.empenhoService.removerUsuarioEmpenho(id).then((r) => {
      this.ngOnInit();
    }).catch((e) => console.log(e))
  }
  pegarAnpet() {

    // let env = { cpf: "00000000000" };
    let env = { cpf: this.filtroCpf };
    this.userservice.getUsuarioEmpenho(env).then((res) => {
      this.candidato.nome = res.nome;
      this.candidato.id = res.id;
      this.candidato.cpf = res.cpf;
    })
  }
  verDadosBancarios() {

    let titulo = '<h3> Dados Bancários da ANPET</h3>';
    let corpo = '<p>Banco do Brasil <br/>Agência 3652-8 <br/>Conta corrente 6776-8 <br/>Associação Nacional de Pesquisa e Ensino em Transportes <br/>CNPJ 31.153.877/0001-46 </p>';
    let mnsg = titulo + corpo;
    let modal = this.dialog.open(ModalBasicoComponent, {
      width: '33vw',
      data: { mnsg: mnsg, buttonOK: true, buttonCancelar: false }
    });
  }
  applyFilter($event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.instituicao = this.instituicaoCheia.filter(function (el) {
      return el.nome.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
    })


  }

}
