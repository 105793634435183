import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class EmpenhoService {

  urlAPI = 'https://backend.anpet.org.br/public/api/'; // api rest fake
  constructor(
    private httpClient: HttpClient,
    ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  getEmpenho(){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'empenho/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  getEmpenhoUnico(id){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'empenho/listar/' + id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  getEmpenhoColuna(coluna,campo){ 
    let res = this.httpClient.get<User>(this.urlAPI + `empenho/listar/${coluna}/${campo}`  ,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  novoEmpenho(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'empenho/cadastrar',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  deletarEmpenho(id){ 
    let obj = {};
    let res = this.httpClient.post<User>(this.urlAPI + `empenho/deletar/${id}`,obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  cadastroUsuarioEmpenho(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'empenho/cadastrar/usuarios',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  removerUsuarioEmpenho(id){ 
    let res = this.httpClient.get<User>(this.urlAPI + `empenho/usuario/remover/${id}`,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  alterarNota(id, fd){ 
    
    let res = this.httpClient.post<User>(this.urlAPI + 'empenho/alterar/nota/'+id,fd,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  alterarRecibo(id, fd){ 
    let res = this.httpClient.post<User>(this.urlAPI + `empenho/alterar/recibo/${id}`,fd,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  //parece que esta fazendo o mesmo trabalho que outra função, checar isso.
  taxUsuarioEmpenho(id){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'empenho/listar/'+id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  
  alterarEmpenho(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'empenho/alterar',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
}
