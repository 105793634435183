import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { User } from '../models/user';
 
@Injectable({
  providedIn: 'root'
})
export class InscricaoService {
  urlAPI = 'https://backend.anpet.org.br/public/api/'; // api rest fake
  constructor(
    private httpClient: HttpClient,
    ) { }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }
  getinstituicao(){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'instituicao/listar',{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  novaInscricaoTed(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'ted/cadastro',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  novaInscricaoPagseguro(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'pagseguro/credencias',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  InscreverUsuario(obj){ 
   
    let res = this.httpClient.post<User>(this.urlAPI + 'inscricoes/cadastrar',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  inscreverUsuariosEmpenho(obj){ 
    let res = this.httpClient.post<User>(this.urlAPI + 'inscricoes/porEmpenho',obj,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  getInscritos(id){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'inscricoes/listar/evento/'+id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
  getInscritosnoEvento(evento,id){ 
    let res = this.httpClient.get<User>(this.urlAPI + 'inscritono/evento/'+evento+'/'+id,{observe: 'response'})
    .toPromise().then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
        return res
  }
}
