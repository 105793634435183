import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-instituicao',
  templateUrl: './modal-instituicao.component.html',
  styleUrls: ['./modal-instituicao.component.scss']
})
export class ModalInstituicaoComponent {
  confirme = new EventEmitter();
  ok:boolean;
  btncancelar:boolean;
  nome: string;
  sigla: string;
  constructor(
    public dialogRef: MatDialogRef<ModalInstituicaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    onAdd = new EventEmitter();
    ngOnInit(){
      this.btncancelar = !this.data.buttonCancelar;
      this.ok = !this.data.buttonOK;
    }
  onNoClick(): void {
    let obj = {
      status: 'ok',
      nome: this.nome,
      sigla: this.sigla
    }
    this.onAdd.emit();
    this.dialogRef.close(obj);
  }
  cancelar(){
    this.dialogRef.close();
  }

}
